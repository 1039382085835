<template>
  <div>
    <a @click="sair" id="logout-btn"><p>Sair</p></a>
    <div id="powerBiContainer"></div>
  </div>
</template>
<script>
import * as pbi from "powerbi-client";
export default {
  name: "Powerbi",
  data() {
    return {
      localPowerBi: "",
    };
  },
  mounted() {

    this.localPowerBi = JSON.parse(localStorage.getItem("powerbi"));
    let embedConfiguration = {

      accessToken: this.localPowerBi.embedToken.token,
      embedUrl: this.localPowerBi.embedReport[0].embedUrl,
      id: this.localPowerBi.embedReport[0].reportId,
      permissions: pbi.models.Permissions.All,
      tokenType: pbi.models.TokenType.Embed,
      type: "report",
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true,
      },
    };
    var reportContainer = document.getElementById("powerBiContainer");
    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    let report = powerbi.embed(reportContainer, embedConfiguration);
    report.on("loaded", function () {
      console.log("Report load successful");
    });
    report.on("rendered", function () {
      console.log("Report render successful");
    });
    report.on("error", function (event) {
      var errorMsg = event.detail;
      console.error(errorMsg);
      return;
    });
  },
  methods:{
    sair() {
      window.localStorage.removeItem('powerbi');
      this.$store.commit('user/SET_DESLOGAR');
    },
  }
};
</script>
<style scoped>
#powerBiContainer {
  width: 100%;
  height: 100%;
}
#logout-btn{
  display: flex;
  justify-content: end;
  margin-top: 25px;
}
#logout-btn p{
  margin-right: 50px;
  color: white;
  cursor: pointer;
}
</style>